import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { onError } from 'apollo-link-error';
import * as LOG from 'loglevel';

if(process.env.REACT_APP_LOCAL_API) {
    LOG.info("Using local apis");
}

export const coreServiceUrl = (suffix="") => {
    const baseUrl = (process.env.REACT_APP_LOCAL_API !== undefined) ?
        "/api" :
        "/api"
        //"https://api.engaged.school:3443"

    return baseUrl + suffix
}


// Set up a trace middleware for New Relic GraphQL operation names for the interaction name
// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/setname-browser-spa-api/
// https://www.apollographql.com/docs/react/v2/networking/network-layer/#middleware
let httpLink = new HttpLink({uri: "/graphql"})

let newRelicTraceMiddleware = new ApolloLink((operation, forward) => {

    if( window.newrelic ) {
        // Credit to: https://github.com/ElevenSymbols/orca/blob/2f9fc3e4391c5aa89671b5b89816ffa9713fcfe8/frontend/src/utils/apollo-client.js#L9
        return new Observable((observer) => {
            let handle, tracer //, start
            Promise.resolve(operation)
                .then((op) => {
                    tracer = window.newrelic.interaction().createTracer("GraphQL:" + op.operationName)
                    //start = Date.now()
                })
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                    })
                })
                .catch(observer.error.bind(observer))
            
            return () => {
                tracer()
                //console.log("GraphQL:" + operation.operationName, Date.now() - start, "ms elapsed")

                if( handle ) handle.unsubscribe()
            }
        })
    } else {
        return forward(operation)
    }
})

let errorAfterware = onError( ({graphQLErrors, networkError, operation})=> {
    if( networkError ) {
        console.error( "GraphQL Network Error:", operation.operationName, networkError.statusCode, networkError.message)
    }

    if( graphQLErrors?.length ) {
        graphQLErrors.forEach( error => {
            console.error( "GraphQL Error:", error.source?.name, error.name, error.message)
        })
    }
})

// https://www.apollographql.com/docs/react/api/core/ApolloClient/

const CLIENT = new ApolloClient({
    name: "engaged-portal",
    //version: "1.0..",
    link: newRelicTraceMiddleware.concat(errorAfterware).concat(httpLink),
    //uri: "https://api.engaged.school:8443/v1/graphql",
    // defaultOptions: {
    //     query: {
    //         fetchPolicy: 'cache-first'
    //     }
    // },
    cache: new InMemoryCache(
        {
            typePolicies: {
                Query: {
                    fields: {
                        organization: {
                            merge(_ignored, incoming) {
                                return incoming
                            }
                        }
                    }
                },
                organization: {
                    fields: {
                        org_units: {
                            merge(_ignored, incoming) {
                                return incoming
                            }
                        }
                    }
                },
                org_unit: {
                    fields: {
                        rosters: {
                            merge(_ignored, incoming) {
                                return incoming
                            }
                        }
                    }
                },
            }
        }
    )

    // defaultOptions = {
    //     watchQuery: {
    //       fetchPolicy: 'cache-and-network',
    //       errorPolicy: 'ignore',
    //     },
    //     query: {
    //       fetchPolicy: 'network-only',
    //       errorPolicy: 'all',
    //     },
    //     mutate: {
    //       errorPolicy: 'all',
    //     },
    //   }

});

export const graphQLClient = () => {
    return CLIENT
}

// const cache = new InMemoryCache({
//     typePolicies: {
//       Book: {
//         fields: {
//           author: {
//             merge(_ignored, incoming) {
//               return incoming;
//             },
//           },
//         },
//       },
//     },
//   });