import { useEffect, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import UrlPattern from 'url-pattern'

//@ts-ignore
import * as sha256 from "hash.js/lib/hash/sha/256"

import * as LOG from "loglevel"

import newrelic from 'new-relic-browser';

/**
 * Extend our window interface to support google analytics and newrelic tracking.
 * 
 */
declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: { page_path: string }
    ) => void

    newrelic?: typeof newrelic
  }
}



type AttributeValue = string|number|object|undefined

interface Attributes {
  [attributes: string]: AttributeValue
}

const encode = (value: AttributeValue): string | number => {
  // Number
  if (typeof value === 'number') {
    return value
  }
  // String
  else if (typeof value === 'string') {
    //@ts-ignore 
    return value.startsWith("###") ? btoa(sha256().update(value).digest()) : value
  } else {
    return JSON.stringify(value)
  }
}

export const trackAttributes = (params: Attributes): void => {
  if (!window.newrelic) return

  if (params && window.newrelic) {
    Object.entries(params).forEach(([k, v]) => {
      LOG.debug("Tracking value", k, encode(v))
      if( v !== undefined) {
        window?.newrelic?.setCustomAttribute(k, encode(v))
      }
    });
  }
}

/** 
 * A hook to track react history changes and generate page events
 * 
 * All credit to https://medium.com/javascript-in-plain-english/google-analytics-with-react-router-and-hooks-16d403ddc528
 * 
 */
export const useLocationChangeEvent = (patterns: string[], callback: (it: any) => void | undefined) => {

  const location = useLocation()
  
  // Initialize our state based on paths
  const sortedPatterns = (patterns?.sort( (a, b) => {
    let a_segments = a.split("/").length
    let b_segments = b.split("/").length

    return (a_segments > b_segments) ? 1 : (a_segments < b_segments) ? -1 : 0
  }) || []).reverse()

  const matchers = sortedPatterns.map( pattern => ({pattern, matcher: new UrlPattern(pattern)}) )

  const match = useCallback((path: string) => 
      matchers.map( ({pattern, matcher}) => 
        ({pattern, variables: matcher.match(path)})).find(({variables}) => variables !== null), [matchers]);

  // Do our actual tracking
  useEffect(() => {
    var event = {...location}
    let matched = match(location.pathname)
    if( matched ) {
      event = {...matched, ...location}
    }
    
    LOG.debug("Location change", event)
    if( callback ) callback(event)

  }, [location, callback, match])

  return {match}
}







const timestamp = () => { return Date.now() }

/**
 * Provides tracking of user activity within a browser window.
 * Probably fails miserably on older browsers...
 * 
 * @param distractionSeconds The number of seconds with noactivity before a user will be considered idle
 * @param startTime A time to start
 */
export const useInteractionTimer = (distractionSeconds = 30, startTime = timestamp()) => {

  var idleTime = 0
  var activeTime = 0
  var clock = startTime
  var active = true

  const activate = (reason: string) => {
    let now = timestamp()

    if (!active) {
      //console.log("activate", reason, active, now, clock, now-clock)
      idleTime += (now - clock)
      clock = now
      active = true
    } else {
      activeTime += (now - clock)
      clock = now
    }
  }

  const idle = (reason: string) => {
    let now = timestamp()

    if (active) {
      //console.log("idle", reason, active, now, clock, now - clock)
      activeTime += (now - clock)
      clock = now
      active = false
    } else {
      idleTime += (now - clock)
      clock = now
    }
  }

  useEffect(() => {

    registerListeners()
    
    // Need to idle the user if there has not been any activity in a period of time...
    let timer = setInterval(function () {
      let now = timestamp()

      if (active && (now - clock) > distractionSeconds * 1000) {
        idle("distracted")
      }
    }, 1000);

    
    return () => {
      clearInterval(timer)
      destroyListeners()
    }
  })



  const getTimers = () => {
    let now = timestamp()

    return ({
      idle: !active ? idleTime + (now - clock) : idleTime,
      active: active ? activeTime + (now - clock) : activeTime,
      start: startTime,
      end: now
    })
  }


  // These are "real" functions because the event listener needs to be able to deregister them
  function handleActivation(event: Event) {
    activate(event.type)
  }

  function handleIdle(event: MouseEvent | FocusEvent) {
    if( !event.relatedTarget ) {
      idle(event.type)
    }
  }

  function registerListeners() {

  }

  function destroyListeners() {
    // User has left or is leaving
    window.removeEventListener('blur', handleIdle)
    window.removeEventListener("mouseout", handleIdle)
    //window.removeEventListener('beforeunload', handleIdle, false)

    // De-idle
    window.removeEventListener('focus', handleActivation, false)
    document.removeEventListener("mousemove", handleActivation, false)
    document.removeEventListener("keyup", handleActivation, false)
    document.removeEventListener("touchstart", handleActivation, false)
    window.removeEventListener("scroll", handleActivation, false)

  }

  return getTimers
}


const scrapeBrowserInfo = () => {

  const { 
      platform, 
      vendor,
      userAgent,
      language,
      languages,
      //@ts-ignore
      deviceMemory,
      hardwareConcurrency,
      maxTouchPoints,
      //@ts-ignore
      connection,
      doNotTrack,
   } = window.navigator

   return {
      platform, 
      vendor,
      userAgent,
      language,
      languages,
      deviceMemory,
      hardwareConcurrency,
      maxTouchPoints,
      connection,
      doNotTrack,

      deviceOrientation: window.orientation,

      resolution: {
          sx: window?.screen?.width,
          sy: window?.screen?.height,
          pr: window?.devicePixelRatio,
          wix: window?.innerWidth,
          wiy: window?.innerHeight,
      }
   }
}

export const useBrowserDescription = () => {

  const browserInfo: any = scrapeBrowserInfo()
  
  const [ipAddress, setIpAddress] = useState<String>()

  useEffect(() => {
      // Look up our ip address
      (async () => {
          try {
            const response = await(fetch("https://api.ipify.org?format=json"))
            const json = await response.json()
            setIpAddress(json?.ip)
          } catch (error) {
            setIpAddress("failed");
            // Failed to look up IP address;
          }
      })()
  }, [])

  return {
    ipAddress,
    browserInfo
  }

}



/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  trackAttributes,
  useLocationChangeEvent,
  useInteractionTimer,
  useBrowserDescription
}