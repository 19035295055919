import { useMediaQuery } from 'react-responsive'

export const useMediaStyles = () => {

    const isDesktopOrLaptop = useMediaQuery({query: '(min-device-width: 1224px)'});
    const isBiggerThanMobile = useMediaQuery({query: '(min-width: 320px)'});
    const isMobile = useMediaQuery({query: '(max-width: 380px'});
    const isTiny = useMediaQuery({query: '(max-width: 150px)'})

    const mediaClassName = () => isMobile ? "mobile" : "other" // TODO: This isn't mobile first but...
    
    return {
        isDesktopOrLaptop,
        isBiggerThanMobile,
        isMobile,
        isTiny,
        mediaClassName: mediaClassName()
    }
}