import React, {useContext, useEffect, useMemo, ReactNode, useCallback} from 'react';
import { Segment, Header, Breadcrumb, Dropdown, Loader } from 'semantic-ui-react';

import { useImmerReducer } from 'use-immer';

import gql from 'graphql-tag';

import { useAuthenticatedLazyQuery, useAuthenticatedQuery } from '../components/FetchHooks';

import * as LOG from 'loglevel';
import { ManagedAccordion } from './ManagedAccordion';
import { useAuthenticated } from './Auth';
import { OrganizationType, RosterType, SurveyType, UnitType } from './EntityTypes';
import { Link, NavigateOptions, NavLink, useLocation, useNavigate } from 'react-router-dom';

const ORG_PATH_PREFIX_PATTERN = /^\/o\/(?<orgId>[^/]+)\/u\/(?<unitId>[^/]+)\/r\/(?<rosterId>[^/]+)\/s\/(?<surveyId>[^/]+)/

// We want a context object to maintain the scope that can be read across our components
export const StaffOrganizationalScopeContext = React.createContext<OrganizationalScopeType>({
    pathPrefix: "",
    organizationalPath: (path: string|undefined) => "",
    navigateOrg: (path: string, options?: NavigateOptions) => {},
    orgs: [],
    units: [],
    rosters: [],
    surveys: [],
    selectOrg: (id: string|undefined) => {},
    selectUnit: (id: string|undefined) => {},
    selectRoster: (id: string|undefined) => {},
    selectSurvey: (id: string|undefined) => {},
    reload: async () => {},
    loading: true,
});

interface OrganizationalNavType {
    pathPrefix: string

    orgs: any[]
    currentOrg?: OrganizationType
    currentOrgId?: string

    units: any[]
    currentUnit?: UnitType
    currentUnitId?: string

    rosters: any[]
    currentRoster?: RosterType
    currentRosterId?: string

    surveys: any[]
    currentSurvey?: SurveyType
    currentSurveyId?: string
}

interface OrganizationalScopeType extends OrganizationalNavType {

    organizationalPath: (path: string|undefined) => string
    navigateOrg: (path: string, options?: NavigateOptions) => void,

    selectOrg: (id: string|undefined) => void
    selectUnit: (id: string|undefined) => void
    selectRoster: (id: string|undefined) => void
    selectSurvey: (id: string|undefined) => void

    reload: ({orgId, unitId, rosterId, surveyId}: {orgId?: string, unitId?: string, rosterId?: string, surveyId?: string}) => Promise<void>

    loading: boolean
    error?: any
}

const OrgScopes_QUERY = gql`
    query OrganizationTree {
        organization(limit: 20,order_by:{name: asc}) {
            id
            name
            clientId
            domain
            domains
            units: org_units(order_by: {name: asc}) {
                id
                clientId
                name
                mainOffice
                rosters(order_by: {endDate: desc, name: asc}) {
                    id
                    name
                    clientId
                    startDate
                    endDate
                    active
                  	surveys: roster_surveys(order_by: {created_at: desc}) {
                      id
                      type
                      name: displayName
                      audienceType
                      contentType
                      type
                      templateId
                      exportSheetId
                      survey_template {
                        id
                        name
                        type
                      }
                      active
                    }
                }
            }
        }
    }
`;

type OrgReducerAction = {
    type: 'clear' | 'initialize' | 'chooseOrg' | 'chooseUnit' | 'chooseRoster' | 'chooseSurvey' | 'pathChanged'
    id?: string
    orgs?: any
    path?: string
}

export const reducer = (draft: OrganizationalNavType, action: OrgReducerAction) => {

    const getOrgs = () => draft.orgs || [];
    const findOrg = (id?: string) => getOrgs().find( (it: any) => it.id === id);
    const getUnits = () => (findOrg(draft.currentOrgId) || {}).units || [];
    const findUnit = (id?: string) => getUnits().find( (it: any) => it.id === id );
    const getRosters = () => (findUnit(draft.currentUnitId) || {}).rosters || [];
    const findRoster = (id?: string) => getRosters().find( (it: any) => it.id === id );
    const getSurveys = () => (findRoster(draft.currentRosterId) || {}).surveys || [];
    const findSurvey = (id?: string) => getSurveys().find( (it: any) => it.id === id );

    if( action && action.type === 'clear') {
        draft.orgs = []
        draft.currentOrgId = undefined
        draft.currentUnitId = undefined
        draft.currentRosterId = undefined
        draft.currentSurveyId = undefined
    }

    if( action && action.type === 'initialize') {
        draft.orgs = action.orgs;

        let pathMatch = action.path?.match(ORG_PATH_PREFIX_PATTERN)

        draft.currentOrgId = pathMatch?.groups?.orgId
        draft.currentUnitId = pathMatch?.groups?.unitId
        draft.currentRosterId = pathMatch?.groups?.rosterId
        draft.currentSurveyId = pathMatch?.groups?.surveyId

        if( findSurvey(draft.currentSurveyId) ) {
            if( findRoster(draft.currentRosterId) ) {
                if( findUnit(draft.currentUnitId) ) {
                    if( findOrg(draft.currentOrgId) ) {
                        // no change
                    } else {
                        draft.currentOrgId = undefined
                    }
                } else {
                    draft.currentUnitId = undefined
                }
            } else {
                draft.currentRosterId = undefined
            }
        } else {
            draft.currentSurveyId = undefined
        }
    }

    if (action) {
        switch (action.type) {
            case 'chooseOrg':
                if (findOrg(action.id)) {
                    draft.currentOrgId = action.id;
                    draft.currentUnitId = undefined
                    draft.currentRosterId = undefined;
                    draft.currentSurveyId = undefined;
                } else {
                    LOG.warn(action.type, " invalid id ", action.id, getOrgs());
                }
                break;
            case 'chooseUnit':
                if (findUnit(action.id)) {
                    draft.currentUnitId = action.id;
                    draft.currentRosterId = undefined;
                    draft.currentSurveyId = undefined;
                } else {
                    LOG.warn(action.type, " invalid id ", action.id, getUnits());
                }
                break;
            case 'chooseRoster':
                if (findRoster(action.id)) {
                    draft.currentRosterId = action.id;
                    draft.currentSurveyId = undefined;
                } else {
                    LOG.warn(action.type, " invalid id ", action.id, getRosters());
                }
                break;
            case 'chooseSurvey':
                if (findSurvey(action.id)) {
                    draft.currentSurveyId = action.id;
                } else {
                    LOG.warn(action.type, " invalid id ", action.id, getSurveys());
                }
                break;
                
            case 'initialize':
            default:
                break;
        }
    }

    if( draft.currentOrgId === undefined && getOrgs().length > 0 ) {
        draft.currentOrgId = getOrgs()[0].id;
    }

    if( draft.currentUnitId === undefined && getUnits().length > 0 ) {
        draft.currentUnitId = getUnits()[0].id;
    }

    if( draft.currentRosterId === undefined && getRosters().length > 0 ) {
        draft.currentRosterId = getRosters()[0].id
    }

    if( draft.currentSurveyId === undefined && getSurveys().length > 0 ) {
        draft.currentSurveyId = getSurveys()[0].id
    }

    draft.orgs = getOrgs();
    draft.currentOrg = findOrg(draft.currentOrgId);

    draft.units = getUnits();
    draft.currentUnit = findUnit(draft.currentUnitId);

    draft.rosters = getRosters();
    draft.currentRoster = findRoster(draft.currentRosterId);

    draft.surveys = getSurveys();
    draft.currentSurvey = findSurvey(draft.currentSurveyId);

    draft.pathPrefix = `/o/${draft.currentOrgId || '_'}/u/${draft.currentUnitId ||'_'}/r/${draft.currentRosterId || '_'}/s/${draft.currentSurveyId || '_'}`

    return draft
};


export const OrganizationalScope = ({children}: {children: ReactNode | ReactNode[]}) => {

    const {user, isAuthenticated, hasRole} = useAuthenticated()

    const {pathname} = useLocation()
    const navigate = useNavigate()

    // const {loading, error, data: orgData, refetch} = useLazyQuery( OrgScopes_QUERY, undefined, {
    //     //fetchPolicy: "network-only"
    //     skip: (isAuthenticated() !== true) || hasRole("guest")
    // } )
    
    // We use this because useQuery doesn't actually respoect the skip parameter...
    // See: https://github.com/apollographql/apollo-client/pull/6752#discussion_r465023997
    let skip = (isAuthenticated() !== true) || hasRole("guest")
    let [fetchOrgScopes, {loading, error, data: orgData, refetch}] = 
        useAuthenticatedLazyQuery( OrgScopes_QUERY, ['admin','roster_admin','leadership','counselor','advisor','staff'], {skip})

    useEffect(() => {
        if(!skip) {
            fetchOrgScopes().then(() => {
                console.log("Fetched org scopes")
            }).catch((err) => {
                console.error("Error fetching org scopes", err)
            })
        }
    }, [skip, fetchOrgScopes])
    
    const [state, dispatch] = useImmerReducer<OrganizationalNavType,OrgReducerAction>(reducer, {
        orgs: orgData?.organization,
        units: [],
        rosters: [],
        surveys: [],
        pathPrefix: ""
    });

    // Refetch when we change users...
    useEffect(() => {
        //@ts-ignore
        dispatch({type: 'clear'});
        if( user?.email ) {
            (async () => await refetch())()
        }
    }, [user?.email, refetch, dispatch])

    useEffect( () => {
        if( orgData ) {
            console.log("Org Data Changed, re-initializing Organizational Scope reducer")
            //@ts-ignore
            dispatch({type: "initialize", orgs: orgData.organization, path: pathname});
        }
    }, [orgData, pathname, dispatch]);

    const organizationalPath = useCallback((inputPath: string) => {
        let targetPrefix = state.pathPrefix

        if( state.currentOrgId === undefined ) {
            return inputPath
        }
        else if( inputPath.match(ORG_PATH_PREFIX_PATTERN) ) {
            if( inputPath.startsWith(targetPrefix) ) {
                return inputPath
            }
            else {
                return inputPath.replace(ORG_PATH_PREFIX_PATTERN, targetPrefix)
            }
        } else {
            // We need to prefix our path with our org nav
            return targetPrefix.replace(/\/$/, '') + '/' + inputPath.replace(/^\//,'')            
        }

    }, [state.pathPrefix, state.currentOrgId])

    const navigateOrg = useCallback((inputPath: string, options?: NavigateOptions) => {
        let orgPrefixedPath = organizationalPath(inputPath)
        navigate(orgPrefixedPath, options)
    }, [organizationalPath, navigate])

    const selectOrg = useCallback((id: string) => {
        //@ts-ignore
        if( id ) dispatch({type: 'chooseOrg', id});
    }, [dispatch])

    const selectUnit = useCallback((id: string) => {
        //@ts-ignore
        if( id ) dispatch({type: 'chooseUnit', id});
    }, [dispatch])

    const selectRoster = useCallback((id: string) => {
        //@ts-ignore
        if( id ) dispatch({type: 'chooseRoster', id});
    }, [dispatch])

    const selectSurvey = useCallback((id: string) => {
        //@ts-ignore
        if( id ) dispatch({type: 'chooseSurvey', id});
    }, [dispatch])

    //@ts-ignore
    const context: OrganizationalScopeType = useMemo( () => {
        LOG.debug("OrganizationalScope context changed", state, loading, error)

        return {
            ...state,

            organizationalPath,
            navigateOrg,

            selectOrg,
            selectUnit,
            selectRoster,
            selectSurvey,
            
            reload: async ({orgId, unitId, rosterId, surveyId}: {orgId?: string, unitId?: string, rosterId?: string, surveyId?: string}) => {
                //await fetchOrgScopes()
                await refetch()
                if( orgId ) {
                    selectOrg(orgId)
                }
                if( unitId ) {
                    selectUnit(unitId)
                }
                if( rosterId ) {
                    selectRoster(rosterId)
                }
                if( surveyId ) {
                    selectSurvey(surveyId)
                }
            },

            loading,
            error
        }
    },
    // We know dispatch is the key for the hooks. If it changes all of the select* functions will change
    // eslint-disable-next-line react-hooks/exhaustive-deps  
    [state, loading, error, refetch, dispatch])


    LOG.debug("OrganizationalScope updated", context)

    // WARNING: Ignoring errors here because apollo skip doesn't even effing work on lazy queries...
    // See https://github.com/apollographql/apollo-client/pull/6752#discussion_r465023997
    // if( error && error.message != `Error: field "organization" not found in type: 'query_root'`) {
    //     LOG.error("ORG SCOPE INITIALIZING ERROR:", error, error.name, error.graphQLErrors)
    //     return null
    // } else {
        return (<StaffOrganizationalScopeContext.Provider
            value={context}>
            {children}
        </StaffOrganizationalScopeContext.Provider>)
    // }
};

export const ConditionalOrganizationalScope = ({skip, children}: {skip: boolean, children: ReactNode | ReactNode[]}) => {
    return skip ? <>{children}</> : <OrganizationalScope>{children}</OrganizationalScope>
}

export const useOrganizationalScope = () => {
    return useContext(StaffOrganizationalScopeContext);
}

export const ScopeMenu = () => {
    const {
        orgs, currentOrg, selectOrg,
        units, currentUnit, selectUnit,
        rosters, currentRoster, selectRoster,
        surveys, currentSurvey, selectSurvey,
        error, loading
    } = useOrganizationalScope()

    const renderDropdown = (collection: any[], current: any, changeSelection: (id: string) => void) => {
        const options = collection?.map((it: any) => {
            return {
                key: it.id,
                text: it.name || it.displayName,
                value: it.id,
            };
        }) || [];

        if (options.length > 1) {
            return (
                <Dropdown
                    inline floating
                    options={options}
                    value={current?.id}
                    onChange={(_: any, { value }: any) => {
                        let matching = collection?.find((it: any) => it.id === value);
                        changeSelection(matching.id);
                    }}
                    trigger={<span>{current?.name || "NONE"}</span>} />
            );
        }
        else if (options.length === 1) {
            return options[0].text;
        }
        else {
            return "NONE";
        }
    };

    const renderDivider = (): ReactNode => (<Breadcrumb.Divider icon='right chevron' />)

    const renderSection = (type: "orgs" | "units" | "rosters" | "surveys", collection: any[] | undefined, current: any, changeSelection: (id: string) => void | undefined, divider = renderDivider) => {
        if (!collection?.length) {
            return null
        } else {
            return (
                <React.Fragment>
                    <Breadcrumb.Divider icon='right chevron' />
                    <Breadcrumb.Section className={type}>
                        {renderDropdown(collection, current, changeSelection)}
                    </Breadcrumb.Section>
                </React.Fragment>
            )
        }
    }

    if (currentOrg) {
        return (<Breadcrumb size="large">
            {orgs?.length > 1 && renderSection("orgs", orgs, currentOrg, selectOrg as (id: string) => void)}
            {renderSection("units", units, currentUnit, selectUnit as (id: string) => void)}
            {renderSection("rosters", rosters, currentRoster, selectRoster as (id: string) => void)}
            {renderSection("surveys", surveys, currentSurvey, selectSurvey as (id: string) => void, () => undefined)}
        </Breadcrumb>);
    }
    else if( loading ) {
        return <Breadcrumb size="large"><div>Loading Organizations <Loader size={'mini'} active inline/></div></Breadcrumb>;
    } else if( error ) {
        console.error("Error loading organizations", error)
        return <Breadcrumb size="large"><div>Error Loading Organizations</div></Breadcrumb>;
    } else {
        return <Breadcrumb size="large"><div>No Organizations</div></Breadcrumb>;
    }
};




const Organizations_QUERY = gql`
    query AllOrganizations {
        organization {
            id
            name
            clientId
            domain
            domains
            org_units(order_by: {mainOffice: asc}) {
                id
                clientId
                name
                mainOffice
                rosters(order_by: {endDate: desc}) {
                    id
                    clientId
                    name
                    startDate
                    endDate
                    participants_aggregate {
                        aggregate {
                            count
                        }
                    }
                    staff_aggregate {
                        aggregate {
                            count
                        }
                    }
                  	surveys: roster_surveys {
                      id
                      type
                      displayName
                      survey_template {
                        id
                      }
                      survey_responses_aggregate {
                        aggregate {
                          count
                        }
                      }
                    }
                }
            }
        }
    }
`;

interface OrgAwareLinkProps extends React.ComponentPropsWithRef<typeof Link> {}

export const OrgAwareLink = ({to, children, ...rest}: OrgAwareLinkProps) => {
    let {organizationalPath} = useOrganizationalScope()

    return <Link to={organizationalPath(to as string)} {...rest}>{children}</Link>
}

export const OrgAwareNavLink = ({to, children, ...rest}: OrgAwareLinkProps) => {
    let {organizationalPath} = useOrganizationalScope()

    return <NavLink to={organizationalPath(to as string)} {...rest}>{children}</NavLink>
}


let OrganizationalRouteUpdater = ({children}: {children: any}) => {
    let {organizationalPath} = useOrganizationalScope()

    let {pathname} = useLocation()
    let navigate = useNavigate()

    useEffect(() => {
        let newPath = organizationalPath(pathname)

        if (newPath !== pathname) {
            console.log("Org Path update from", pathname, "to", newPath);
            navigate(newPath)
        }
    }, [organizationalPath, pathname, navigate])

    return children
} 

export const OrganizationallyPathed = ({children}: {children: React.ReactElement}) => {
    return <OrganizationalRouteUpdater>{children}</OrganizationalRouteUpdater>
}





export const OrganizationTree = () => {
    const { data } = useAuthenticatedQuery(Organizations_QUERY, "admin");

    return (
        <Segment>
            <Header as="h2">Organizations</Header>
            <ManagedAccordion
                data={data?.organization || []}
                TitleContent={({ item: org }) => (<span>{org.name} - {org.domain}</span>)}
                Content={({ item: org }) => (
                    <Segment>
                        <Header as="h3">Units</Header>
                        <ManagedAccordion
                            data={org.org_units}
                            TitleContent={({ item: unit }) => (<span>{unit.name} ({unit.clientId})</span>)}
                            Content={({ item: unit }) => {
                                return unit.mainOffice ? (<span>Main Office</span>) :
                                    (<Segment>
                                        <Header as="h4">Rosters</Header>
                                        <ManagedAccordion
                                            data={unit.rosters}
                                            TitleContent={({ item: roster }) => (<span>{roster.name} ({roster.clientId})</span>)}
                                            Content={({ item: roster }) => (
                                                <Segment>
                                                    <Header as="h5">Surveys</Header>
                                                    <ManagedAccordion
                                                        data={roster.surveys}
                                                        TitleContent={({ item: survey }) => (<span>{survey.displayName} - {survey.type}</span>)}
                                                        Content={({ item: survey }) => (
                                                            <span>{survey.survey_responses_aggregate.aggregate.count} responses</span>
                                                        )} />
                                                </Segment>
                                            )} />
                                    </Segment>);
                            }} />
                    </Segment>
                )} />
        </Segment>
    );
};

