import * as LOG from 'loglevel';
import {coreServiceUrl} from '../Config';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { User } from '../components/Auth';

const TOKENSIGNIN_ENDPOINT = coreServiceUrl("/auth/tokensignin");
const IMPERSONATE_ENDPOINT = coreServiceUrl("/auth/impersonate")


type GoogleLoginData = GoogleLoginResponse|GoogleLoginResponseOffline

/**
 * Converts a google id_token to an auth token that our services can use.
 * Submit the token to the engaged.school API endpoints to register the user, 
 * record the login and generate an access token
 * 
 * @param googleAuth The google authentication object
 * @returns A map containing the googleIdenty and authorizationToken keys, or undefined if there was an error.
 */
export const retrieveAuthToken = async (googleAuth: GoogleLoginData, context: any) => {
    if( "token" in googleAuth ) {
      LOG.error("Unable to do token signin. Offline token...");
    }

    var data = {
        token: (googleAuth as GoogleLoginResponse).getAuthResponse().id_token,
        provider: "GOOGLE",
        context
    };

    LOG.debug('Token Signin with: ', data);

    return fetch(TOKENSIGNIN_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(data),
        //mode: 'no-cors', //TODO: WHY DOESN'T CORS WORK!
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(json => { 

        LOG.debug("Token signin reponse: " + JSON.stringify(json));

        if( json.status === "success") {
            LOG.debug("Successful token signin");
            return json.data;
        } 
        else {
            const msg = (json && json.message) || "unknown issue"
            LOG.error("Unable to do token signin.", msg)
            return undefined;
        }

    }).catch(error => {
        LOG.error("Unable to do token signin.", error);
        return undefined;
    });
}


export const retrieveImpresonationToken = async (currentUser: User, email: string, domain: string) => {

    if( (currentUser === undefined) || (currentUser.authorizationToken === undefined)) {
        throw new Error("User is not authenticated/empty.");
    }
    
    const authToken = currentUser.authorizationToken;

    try {
        const response = await fetch(`${IMPERSONATE_ENDPOINT}/${email}/${domain}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + authToken
          }
        });
    
        const json = await response.json();
        if (json.status && json.status === 'success') {
          const data = json.data;
          
          LOG.debug(`Successfully recieved impersonation token for ${email} in domain ${domain}:`, data);
    
          return data;
        } else {
          LOG.error(`Unable to generate impersonation token for${email} in domain ${domain}.`, json.message)
          return undefined;
        }
      } catch (error) {
        LOG.error(`Error generating impersonation token for ${email} in domain ${domain}.`, error);
        return undefined;
      }

}