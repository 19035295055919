import { useState, useEffect, useCallback } from 'react';
import { useAuthenticated, SupportedRoles } from './Auth';

import { useMutation, QueryHookOptions, DocumentNode, MutationHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import * as LOG from 'loglevel';

const identity = (it: any) => it;


// Note that there are options with the skip option on apollo queries...
// From: https://github.com/apollographql/apollo-client/pull/6752
// https://github.com/apollographql/apollo-client/pull/6752#discussion_r465023997 is a hopeful workaround...


type FetchStateType = {
    loading: boolean
    data?: any
    error?: any
}


export const useJsonFetch = (url: string, {headers = {}, transform = identity} = {}) => {
    
    const [fetchState, setFetchState] = useState<FetchStateType>({ loading: true, data: undefined, error: undefined });
    const { user } = useAuthenticated();

    const doTransform = useCallback(transform, [url,transform]);

    const authorizationToken = user?.authorizationToken;

    useEffect(() => {
        const doFetch = async () => {
            try {
                // Reset our fetch state
                setFetchState({ loading: true, data: undefined, error: undefined });
                LOG.debug("Fetching", url);
                const response = await fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
                const json = await response.json();
                setFetchState({ loading: false, data: doTransform(json), error: undefined });
                LOG.debug("Fetched successfully", url, json);
            }
            catch (err) {
                LOG.warn("Fetch Error", url, err);
                setFetchState({ loading: false, data: undefined, error: err });
            }
        };

        doFetch();

    }, [url, authorizationToken, doTransform]);


    return fetchState;
};

export const useJSendFetch = (url: string, {conditional = true} = {}) => {

    const [fetchState, setFetchState] = useState<FetchStateType>({ loading: true, data: undefined, error: undefined });
    const { user } = useAuthenticated();

    const authorizationToken = user?.authorizationToken;

    useEffect(() => {
        const doFetch = async () => {

            if( conditional === false ) {
                return;
            }

            try {
                // Reset our fetch state
                setFetchState({ loading: true, data: undefined, error: undefined });
                LOG.debug("Fetching", url);
                const response = await fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
                const json = await response.json();

                if (json && json.status && json.status === 'success') {
                    setFetchState({ loading: false, data: json.data, error: undefined });
                    LOG.debug("Fetched successfully", url);
                }
                else {
                    LOG.debug("Fetched failed", url);
                    throw new Error(`Fetch failed for ${url}: ${json.message}`);
                }
            }
            catch (err) {
                LOG.warn("Fetch Error", url, err);
                setFetchState({ loading: false, data: undefined, error: err });
            }
        };
        
        doFetch();

    }, [url, authorizationToken, conditional]);

    return fetchState; // loading, error, data
};



// Fetch policies are important!
// https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
// https://www.apollographql.com/docs/react/api/core/ApolloClient/#FetchPolicy

export const useAuthenticatedQuery = (query: DocumentNode, roles?: SupportedRoles | SupportedRoles[], options: QueryHookOptions = {}) => {
    const {user, hasRole} = useAuthenticated();

    const token = user?.authorizationToken;
    const skip = options.hasOwnProperty("skip") ? options.skip || !token : !token

    const headers: any = {
        'Authorization': `Bearer ${token}`,
    }
    
    if( roles ) {
        headers['x-hasura-role'] = Array.isArray(roles) ? roles.find(r => hasRole(r)): roles
    }

    return useQuery(query, {
        ...options,
        context: {
            ...options?.context,
            headers
        },
        
        skip
    })
}

export const useAuthenticatedLazyQuery = (query: DocumentNode, roles?: SupportedRoles|SupportedRoles[], options: QueryHookOptions = {}) => {
    const {user, hasRole} = useAuthenticated();

    const token = user?.authorizationToken;

    const headers: any = {
        'Authorization': `Bearer ${token}`,
    }
    
    if( roles ) {
        headers['x-hasura-role'] = Array.isArray(roles) ? roles.find(r => hasRole(r)): roles
    }

    return useLazyQuery(query, {
        ...options,
        context: {
            ...options?.context,
            headers
        }
    })
}


export const useAuthenticatedMutation = (mutation: DocumentNode, roles?: SupportedRoles|SupportedRoles[], options: MutationHookOptions={}) => {
    const {user,hasRole} = useAuthenticated();

    return useMutation(mutation, {
        ...options,
        context: {
            ...options?.context,
            headers: {
                'Authorization': `Bearer ${user?.authorizationToken || '__NONE__'}`,
                ...(roles ? {'x-hasura-role': Array.isArray(roles) ? roles.find(r => hasRole(r)): roles} : {})
            }
         },
    });
}

  