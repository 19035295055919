import React, { useEffect, useMemo, useState } from 'react';
import { Table, Header, Icon, Modal, Button, Segment, Search, SearchProps, Label, Card, List} from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useAuthenticatedLazyQuery } from './FetchHooks';
import { OrgAwareLink, useOrganizationalScope } from './OrganizationalScope';
import { useAuthenticated, useImpersonateUser } from './Auth';
import { DateTime } from 'luxon';
import { debounce, groupBy } from 'lodash';
import { ApolloError } from '@apollo/client';

const FIND_PERSON_QUERY = gql`
    query find_person($query: String!) {
        participant(where: {_or: [{email: {_ilike: $query}}, {firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}, 
            order_by: [{lastName: asc_nulls_last}, {firstName:asc_nulls_last}],
            limit: 20) {
            id
            clientId
            firstName
            lastName
            email
            roster {
                id
                name
                org_unit {
                    id
                    name
                    organization {
                        id
                        name
                    }
                }
            }
            advisory {
                id
                clientId
                name
                roster {
                    id
                    name
                    org_unit {
                        id
                        name
                        organization {
                            id
                            name
                        }
                    }
                }
            }
            user {
                id
                email
                firstName: first_name
                lastName: last_name
                domain
                login_histories(limit: 5, order_by: {login_at: desc_nulls_last}) {
                    login_at
                    ip: context(path:"$.ipAddress")
                    vendor: context(path:"$.browser.vendor")
                    ua: context(path:"$.browser.userAgent")
                    resolution:context(path:"$.browser.resolution")
                    productSub:context(path:"$.browser.productSub")
                }
            }
        }

        staff(where: {_or: [{email: {_ilike: $query}}, {firstName: {_ilike: $query}}, {lastName: {_ilike: $query}}]}, 
            order_by: [{lastName: asc_nulls_last}, {firstName:asc_nulls_last}],
            limit: 20) {
            id
            clientId
            firstName
            lastName
            email
            roster {
                id
                name
                org_unit {
                    id
                    name
                    organization {
                        id
                        name
                    }
                }
            }
            advisory {
                id
                clientId
                name
                roster {
                    id
                    name
                    org_unit {
                        id
                        name
                        organization {
                            id
                            name
                        }
                    }
                }
            }
            user {
                id
                email
                firstName: first_name
                lastName: last_name
                domain
                login_histories(limit: 5, order_by: {login_at: desc_nulls_last}) {
                    login_at
                    ip: context(path:"$.ipAddress")
                    vendor: context(path:"$.browser.vendor")
                    ua: context(path:"$.browser.userAgent")
                    resolution:context(path:"$.browser.resolution")
                    productSub:context(path:"$.browser.productSub")
                }
            }
        }
        user(where: {_or: [{email: {_ilike: $query}}, {first_name: {_ilike: $query}}, {last_name: {_ilike: $query}}]}, 
            order_by: [{last_name: asc_nulls_last}, {first_name:asc_nulls_last}],
            limit: 20) {
            id
            email
            firstName: first_name
            lastName: last_name
            domain
            organization {
                id
                name
            }
            login_histories(limit: 5, order_by: {login_at: desc_nulls_last}) {
                login_at
                ip: context(path:"$.ipAddress")
                vendor: context(path:"$.browser.vendor")
                ua: context(path:"$.browser.userAgent")
                resolution:context(path:"$.browser.resolution")
                productSub:context(path:"$.browser.productSub")
            }
        }
    }
`;

// The aggregate is far to slow in bulk for some reason. Probably need a view here...
// login_histories_aggregate {
//     aggregate {
//         count
//         max {
//             login_at
//         }
//     }
// }


// const PersonSearchFormSchema = {
//     title: 'Find Person',
//     type: 'object',
//     properties: {
//         search: { 
//             type: 'string', minLength: 4, maxLength: 100,
//         }
//     },
//     required: ['search'],
// };


interface PersonSearchResultItem {
    type?: string,
    email: string
    firstName: string
    lastName: string
    clientId?: string
    rosters?: any[]
    advisories?: any[]
    entities?: any[]
}

export type PersonSearchWidgetProps = {
    executeSearch: (input: string) => void,
    data: any,
    loading: boolean,
    error?: ApolloError,
    changeOrgSelection: (orgId: string, unitId: string, rosterId: string) => void,
    isAdmin: boolean,
    impersonateUser: (email: string) => void
}

export const PersonSearchWidget = ({
    data,
    executeSearch,
    loading,
    isAdmin,
    impersonateUser,
    changeOrgSelection
}: PersonSearchWidgetProps) => {

    const [value, setValue] = useState<string|undefined>()
    const [results, setResults] = useState<Record<string,any>>({})

    // Transform the search result data to the format needed for the Search component
    useEffect(() => {

        if( data === undefined ) {
            setResults({})
            return;
        }

        let participantGroups = groupBy(data.participant, 
            (it: PersonSearchResultItem) => [it.email, it.firstName, it.lastName, it.clientId].map(i => i?.toLowerCase()).join(""))

        let participants = Object.values(participantGroups).map( entries => 
            ({
                title: '',
                description: '',
                type: "PARTICIPANT",
                ...entries[0],
                key: entries[0].id,
                actualId: entries[0].id,
                entities: entries,
                rosters: entries.map( it => it.roster ),
                advisories: entries.map( it => it.advisory ),
                user: entries.map( it => it.user )?.[0]
                //lastLogin: entries
            })
        )

        let staffGroups = groupBy(data.staff, 
            (it: PersonSearchResultItem) => [it.email, it.firstName, it.lastName, it.clientId].map(i => i?.toLowerCase()).join(""))

        let staff = Object.values(staffGroups).map( entries =>
            ({
                title: '',
                description: '',
                type: "STAFF",
                ...entries[0],
                key: entries[0].id,
                actualId: entries[0].id,
                entities: entries,
                rosters: entries.map( it => it.roster ),
                advisories: entries.map( it => it.advisory ),
                user: entries.map( it => it.user )?.[0]
            })
        )

        let existingEmails = ([] as any[]).concat(participants).concat(staff).map((it: PersonSearchResultItem) => it.email?.toLowerCase())
        let users = data?.user?.filter( (u: any) => existingEmails.find( ee => ee === u.email?.toLowerCase() ) === undefined )

        if( data !== undefined ) {

            let formatted = {
                Participants: {
                    name: "Participants",
                    results: participants,
                },
                Staff: {
                    name: "Staff",
                    results: staff,
                },
                //TODO: Add back in users that are not participants or staff
                Users: {
                    name: "Users",
                    results: users?.map((user: any) => ({...user, key: user.id, type: "USER"})) || []
                }
            }

            setResults(formatted)

        } else {
            setResults({})
        }

    }, [data])

    const changeHandler = (e: any, {value}: SearchProps) => {
        setValue(value)
        if( value && value.length > 2) {
            //executeSearch(value)
            debouncedSearch(value)
        }
    }
    const debouncedSearch = useMemo(() => debounce(
        (value: string) => executeSearch(value), 300, {trailing: true}),[executeSearch]
    )

    const categoryLayoutRenderer = ({ categoryContent, resultsContent }: {categoryContent: any, resultsContent: any}) => (
        <div>
          <h3 className='name'>{categoryContent}</h3>
          <div className='results'>
            {resultsContent}
          </div>
        </div>
    )

    const categoryRenderer = ({ name }: {name: string}) => <Label as='span' content={name} />
    
    const resultRenderer = ({ firstName, lastName, email, type, entities }: PersonSearchResultItem) => {
        return <Card>
            <Card.Content>
                <Card.Header>{firstName} {lastName}</Card.Header>
                <Card.Meta>{email} {isAdmin && <Icon name="sign-in" size="small" onClick={() => impersonateUser(email)}/>}</Card.Meta>
                <Card.Meta><LoginDetails user={entities?.[0]?.user}/></Card.Meta>
                <Card.Description style={{marginTop: "0.75em"}}>
                    <List divided relaxed>
                        {entities?.filter(entity => entity.advisory?.id !== undefined ).map( entity => <List.Item key={entity.id}>
                            <List.Description>
                                <OrgAwareLink
                                      to={`/o/${entity.roster.org_unit.organization.id}/u/${entity.roster.org_unit.id}/r/${entity.roster.id}/s/_/advisory/${entity.advisory?.id}/student/${type === "PARTICIPANT" ? entity.id : ""}`}>
                                    {entity.advisory?.name} - {entity?.roster?.name}
                                </OrgAwareLink>
                            </List.Description>
                        </List.Item>)}
                    </List>
                </Card.Description>
            </Card.Content>
        </Card>
    }

    return <Search
        role="searchbox"
        category
        loading={loading}
        onSearchChange={changeHandler}
        results={results}
        value={value}
        //@ts-ignore
        resultRenderer={resultRenderer}
        //@ts-ignore
        categoryLayoutRenderer={categoryLayoutRenderer}
        //@ts-ignore
        categoryRenderer={categoryRenderer}

    />
}

export const PersonSearch = () => {

    const { selectOrg, selectUnit, selectRoster } = useOrganizationalScope();
    const { hasRole } = useAuthenticated()

    const [executeQuery, { data, loading, error }] = useAuthenticatedLazyQuery(FIND_PERSON_QUERY, undefined, {});

    const executeSearch = (input: string) => executeQuery({
        variables: {
            query: `${input}%`
        }
    })

    let doImpersonation = useImpersonateUser()

    const changeOrgSelection = (orgId?: string, unitId?: string, rosterId?: string) => {
        orgId && selectOrg(orgId);
        unitId && selectUnit(unitId);
        rosterId && selectRoster(rosterId);
    };

    error && console.error("PersonSearch error", error);

    return <PersonSearchWidget 
        executeSearch={executeSearch}
        data={data}
        loading={loading}
        error={error}
        changeOrgSelection={changeOrgSelection}
        isAdmin={hasRole("admin")}
        impersonateUser={doImpersonation}
    />


}

// export const FindUser = () => {

//     const { selectOrg, selectUnit, selectRoster } = useOrganizationalScope();

//     const [executeQuery, { data }] = useAuthenticatedLazyQuery(FIND_PERSON_QUERY, "admin", {});

//     const doImpersonation = useImpersonateUser()
//     const {hasRole} = useAuthenticated()

//     const onSubmit = async (data: Record<string,any>) => {
//         executeQuery({
//             variables: {
//                 query: `${data.search}%`
//             }
//         });
//     };

//     const changeOrgSelection = (orgId?: string, unitId?: string, rosterId?: string) => {
//         orgId && selectOrg(orgId);
//         unitId && selectUnit(unitId);
//         rosterId && selectRoster(rosterId);
//     };

//     return (
//         <>
//             <Header>Find Person</Header>
//             <AutoForm 
//                 schema={createFormSchema(PersonSearchFormSchema)
//                 onSubmit={onSubmit}
//                 placeholder="Email, First, or Last Name"
//             />
//             <SearchForm
//                 schema={PersonSearchFormSchema} 
//                 onSubmit={onSubmit} 
//                 placeholder="Email, First or Last Name"/>

//             {data && <Table celled structured>
//                 <Table.Header>
//                     <Table.Row>
//                         <Table.HeaderCell>Organization</Table.HeaderCell>
//                         <Table.HeaderCell>First Name</Table.HeaderCell>
//                         <Table.HeaderCell>Last Name</Table.HeaderCell>
//                         <Table.HeaderCell>Email</Table.HeaderCell>
//                         <Table.HeaderCell>Details</Table.HeaderCell>
//                         <Table.HeaderCell>&nbsp;</Table.HeaderCell>
//                     </Table.Row>
//                 </Table.Header>
//                 <Table.Body>

//                     {data?.participant && data?.participant?.length > 0 && <Table.Row active>
//                         <Table.HeaderCell colSpan={6} textAlign="center">Participant Roster</Table.HeaderCell>
//                     </Table.Row>}
//                     {data?.participant.map((participant: any) => (<Table.Row key={participant.id}>
//                         <Table.Cell>
//                             {participant.roster.org_unit.organization.name}
//                         </Table.Cell>
//                         <Table.Cell>{participant.firstName}</Table.Cell>
//                         <Table.Cell>{participant.lastName}</Table.Cell>
//                         <Table.Cell>{participant.email} {hasRole("admin") && <Icon name="user secret" onClick={() => doImpersonation(participant.email)}/>}</Table.Cell>
//                         <Table.Cell>
//                             {participant.roster.org_unit.name}<br />
//                             {participant.roster.name}<br />
//                             <Link onClick={() => changeOrgSelection(participant.roster.org_unit.organization.id, participant.roster.org_unit.id, participant.roster.id)}
//                                 to={`/advisory/${participant.advisory.id}/student/${participant.id}`}>
//                                 {participant.advisory.name}
//                             </Link>
//                         </Table.Cell>
//                         <Table.Cell />
//                     </Table.Row>))}

//                     {data?.staff && data?.staff?.length > 0 && <Table.Row active>
//                         <Table.HeaderCell colSpan={6} textAlign="center">Staff Roster</Table.HeaderCell>
//                     </Table.Row>}
//                     {data?.staff.map((staff: any) => (<Table.Row key={staff.id}>
//                         <Table.Cell>
//                             {staff.roster.org_unit.organization.name}
//                         </Table.Cell>
//                         <Table.Cell>{staff.firstName}</Table.Cell>
//                         <Table.Cell>{staff.lastName}</Table.Cell>
//                         <Table.Cell>{staff.email} {hasRole("admin") && <Icon name="user secret" onClick={() => doImpersonation(staff.email)}/>}</Table.Cell>
//                         <Table.Cell>
//                             {staff.roster.org_unit.name}<br />
//                             {staff.roster.name}<br />
//                             {staff.advisory && <Link
//                                 onClick={() => changeOrgSelection(staff.roster.org_unit.organization.id, staff.roster.org_unit.id, staff.roster.id)}
//                                 to={`/advisory/${staff.advisory.id}/student`}>{staff.advisory.name}</Link>}
//                         </Table.Cell>
//                         <Table.Cell />
//                     </Table.Row>))}

//                     {data?.user && data?.user?.length > 0 && <Table.Row active>
//                         <Table.HeaderCell colSpan={6} textAlign="center">Users</Table.HeaderCell>
//                     </Table.Row>}
//                     {data?.user.map((user: any) => (<Table.Row key={user.id}>
//                         <Table.Cell>{user?.organization?.name}</Table.Cell>
//                         <Table.Cell>{user.first_name}</Table.Cell>
//                         <Table.Cell>{user.last_name}</Table.Cell>
//                         <Table.Cell>{user.email}</Table.Cell>
//                         <Table.Cell><LoginDetails user={user}/></Table.Cell>
//                     <Table.Cell>
//                         {hasRole("admin") && <Icon name="user secret" onClick={() => doImpersonation(user.email)}/>}
//                     </Table.Cell>
//                     </Table.Row>))}
//                 </Table.Body>
//             </Table>}
//         </>
//     );
// };


type LoginHistory = {
    login_at: String
    ip: String
    vendor: String
    resolution: Record<string,string>,
    ua: String
}

type User = {
    email: string
    login_histories: LoginHistory[]
}

const LoginDetails = ({user}: {user: User}) => {

    const [mode, setMode] = useState<"summary"|"detail">("summary")

    if( mode === "detail") {
       return <Modal open={mode === "detail"}>
           <Segment>
                <Header>Recent Login history for {user?.email}</Header>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan={2}>Date/Time</Table.HeaderCell>
                            <Table.HeaderCell rowSpan={2}>IP Address</Table.HeaderCell>
                            <Table.HeaderCell colSpan={3}>Browser</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>Vendor</Table.HeaderCell>
                            <Table.HeaderCell>Resolution</Table.HeaderCell>
                            <Table.HeaderCell>User Agent</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {user?.login_histories?.map((history: any, i: number) => {
                            let dt = DateTime.fromISO(history.login_at).toFormat("yyyy-MM-dd HH:MM") //TODO: Timezone... 
                            return (<Table.Row key={i}>
                                <Table.Cell>{dt}</Table.Cell>
                                <Table.Cell>{history.ip}</Table.Cell>
                                <Table.Cell>{history.vendor}</Table.Cell>
                                <Table.Cell>{JSON.stringify(history.resolution)}</Table.Cell>
                                <Table.Cell>{history.ua}</Table.Cell>
                            </Table.Row>)
                        })}
                    </Table.Body>
                </Table>
                <Button onClick={() => setMode("summary")}>Close</Button>
           </Segment>
        </Modal>
    } else {
        console.log("User", user)
        console.log("Login History", user?.login_histories)

        let buttonLinkStyle = {
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            textDecoration: "underline",
            display: "inline",
            margin: 0,
            padding: 0
        }

        return <p>
            {/* {login_histories_aggregate?.aggregate.count} Logins &nbsp; */}
            { user?.login_histories ?
                <button style={buttonLinkStyle} onClick={() => setMode("detail")}>Last login: {user?.login_histories?.[0]?.login_at?.substring(0,10)}</button> :
                <span>Has not logged in</span> }
        </p>
    }
}